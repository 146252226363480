import $ from 'jquery';
import Swiper from 'swiper/bundle';
import 'swiper/swiper-bundle.css';

$(function () {
  $('.c-mv1__slide').each(function () {
    // Math.PI * (r * 2)
    // console.log(Math.PI * (6.5 * 2));
    new Swiper(this, {
      loop: true,
      speed: 1000,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
        renderBullet: function (i, className) {
          return (
            '<button class="' +
            className +
            '">' +
            '<svg xmlns="http://www.w3.org/2000/svg" class="progress" width="14.3" height="14.3">' +
            '<circle class="circle" r="6.5" cx="7.15" cy="7.15"></circle>' +
            '<circle class="bar" r="6.5" cx="7.15" cy="7.15"></circle>' +
            '<circle cx="7.15" cy="7.15" r="2" fill="#fff"></circle>' +
            '</svg></button>'
          );
        },
      },
    });
  });
});
