/* ======================================
Header
====================================== */
import $ from 'jquery';
const body = $('body');
const wind = $(window);
const bodyHTML = $('body, html');
let scrollPosi;

function bodyFix() {
  scrollPosi = $(window).scrollTop();

  body.addClass('is-fixed').css({ top: -scrollPosi });
}

function bodyFixReset() {
  body.removeClass('is-fixed').css({ top: '0' });
  bodyHTML.scrollTop(scrollPosi);
}

(() => {
  const btn = $('.js-menu');
  const content = $('.c-menuheader__center');
  wind.on('load resize', function () {
    setTimeout(
      () =>
        wind.outerWidth() < 768 && content.css('transition', '.3s ease-in-out'),
      500,
    );
    wind.outerWidth() > 767 && content.removeAttr('style');
  });
  if (btn.length) {
    btn.on('click', function () {
      const header = $(this).parents('.c-menuheader');
      const header_content = header.find('.c-menuheader__center');
      $(this).toggleClass('is-active');
      header_content.toggleClass('is-active');
      header.toggleClass('is-menu-open');
      if (header_content.hasClass('is-active')) {
        bodyFix();
      } else {
        bodyFixReset();
      }
    });

    $(window).on('pageshow', function (event) {
      if (event.originalEvent.persisted) {
        $('.c-menuheader__center').addClass('is-hide');
        resetMenuHeader();

        setTimeout(() => {
          $('.c-menuheader__center').removeClass('is-hide');
        }, 0);
      }
    });
  }
})();

function resetMenuHeader() {
  $('.js-menu').removeClass('is-active');
  $('.c-menuheader__center').removeClass('is-active');
  $('.c-menuheader').removeClass('is-menu-open');
  bodyFixReset();
}
