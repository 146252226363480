import $ from 'jquery';
import {} from './Components/_Filter.js';
import Anchor from './Components/_Anchor.js';
import Swiper from './Components/_Slider.js';
import Header from './Components/_Header.js';

function getScrollbarWidth() {
  const outer = document.createElement('div');
  outer.style.visibility = 'hidden';
  outer.style.overflow = 'scroll';
  outer.style.msOverflowStyle = 'scrollbar';
  document.body.appendChild(outer);
  const inner = document.createElement('div');
  outer.appendChild(inner);
  const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;
  outer.parentNode.removeChild(outer);
  outer.remove();
  return scrollbarWidth;
}

$(function () {
  let elem = $('#js-gotop a');
  if (!elem.length) return;
  let tmp = elem.clone().attr('class', 'is-tmp').css('visibility', 'hidden');
  let parent = elem.parent();
  document.addEventListener('scroll', function () {
    let scrollTop = $(this).scrollTop();
    let parentTop = parent.offset().top;
    let parentHeight = parent.outerHeight();
    let bottom = 20;
    if ($(window).width() <= 767) {
      bottom = 10;
    }
    if (scrollTop > 300) {
      parent.append(tmp);
      elem.addClass('is-show').css({ position: 'fixed', bottom: bottom });
      if (
        scrollTop >=
        parentTop - window.innerHeight + (parentHeight + bottom)
      ) {
        tmp.css({ visibility: '' });
        elem.css({ visibility: 'hidden' });
      } else {
        tmp.css({ visibility: 'hidden' });
        elem.css({ visibility: '' });
      }
    } else {
      parent.find('.is-tmp').remove();
      elem.removeClass('is-show');
    }
  });
});

$(function () {
  let side = $('#js-side-left');
  if (side.length) {
    $(window).on('load scroll resize orientationchange', function () {
      let wrap = side.parent();
      let wrapHeight = wrap.outerHeight();
      let sideHeight = side.outerHeight();
      let headerHeight = $('header').outerHeight();
      let wrapOffsetTop = wrap.offset().top - headerHeight;
      let wrapScrollMax = wrapOffsetTop + (wrapHeight - sideHeight);
      let scrollTop = $(this).scrollTop();
      if (scrollTop >= wrapOffsetTop) {
        side.css({ top: headerHeight }).addClass('is-fixed');
        if (scrollTop >= wrapScrollMax) {
          side.css({ top: wrapScrollMax }).addClass('is-abs');
        } else {
          side.removeClass('is-abs');
        }
      } else {
        side.removeClass('is-fixed');
      }
    });
  }
});

$(function () {
  $('.js-side-link').each(function () {
    let link = $(this);
    link.on('click', function (ev) {
      ev.stopPropagation();
      ev.preventDefault();
      let menu = link.next('.js-side-menu');
      let linkParent = link.parent();
      if (menu.length) {
        menu.css('--vw', window.innerWidth - getScrollbarWidth() + 'px');
        menu.css('--vh', window.innerHeight + 'px');
        $('.js-side-menu.is-active').not(menu).removeClass('is-active');
        $('.js-side-link').parent().not(linkParent).removeClass('is-click');
        menu.toggleClass('is-active');
        linkParent.toggleClass('is-click');
      }
    });
  });

  window.addEventListener('click', function (e) {
    $('.js-side-menu.is-active').each(function () {
      if (!this.contains(e.target)) {
        $(this).removeClass('is-active');
        $(this).parent().removeClass('is-click');
      }
    });
  });
});

$(function () {
  $('.js-submenu-link').each(function () {
    let link = $(this);
    link.on('click', function (ev) {
      ev.stopPropagation();
      ev.preventDefault();
      let menu = link.next('.js-submenu-wrap');
      let linkParent = link.parent();
      if (menu.length) {
        menu.css('--vw', window.innerWidth - getScrollbarWidth() + 'px');
        menu.css('--vh', window.innerHeight + 'px');
        $('.js-submenu-wrap.is-active').not(menu).removeClass('is-active');
        $('.js-submenu-link').parent().not(linkParent).removeClass('is-click');
        menu.toggleClass('is-active');
        linkParent.toggleClass('is-click');
      }
    });
  });

  window.addEventListener('click', function (e) {
    $('.js-submenu-wrap.is-active').each(function () {
      if (!this.contains(e.target)) {
        $(this).removeClass('is-active');
        $(this).parent().removeClass('is-click');
      }
    });
  });
});

/*equalHeight*/
$(function () {
  function equalHeight(group) {
    group.height('');
    let tallest = 0;
    group.each(function () {
      let thisHeight = $(this).height();
      if (thisHeight > tallest) {
        tallest = thisHeight;
      }
    });
    group.height(tallest);
  }

  $(window).on('load resize', function () {
    if ($(window).width() > 767) {
      equalHeight($('.p-system2 .c-block2'));
    } else {
      $('.p-system2 .c-block2').removeAttr('style');
    }
  });
});

// searchinput
let $search_input = $('.c-menuheader__formbox form input');
let $menu_lang = $('.c-menuheader__boxsp1');

$(window).on('load resize', function () {
  if ($(window).width() > 767) {
    $search_input.on('click', function (e) {
      e.preventDefault();
      $search_input.animate({ width: '100px' }, '200');
      $menu_lang.animate({ right: '183px' }, '200');
    });
    $search_input.on('change', function (e) {
      e.preventDefault();
      $search_input.animate({ width: '50px' }, '200');
      $menu_lang.animate({ right: '136px' }, '200');
    });
  } else {
    $search_input.off('click change');
    $search_input.removeAttr('style');
    $menu_lang.removeAttr('style');
  }
});

// js inline block css
$(window).on('load resize', function (e) {
  $('.js-inline').each(function () {
    let displayStyle = $(this).css('display');
    let lineHeight = parseInt($(this).css('line-height'));
    let height = $(this).height();
    let numLines = Math.round(height / lineHeight);
    if (numLines == 1) {
      $(this).css('display', 'block');
    } else {
      $(this).css('display', 'initial');
    }
  });
});
