import $ from 'jquery';
import mixitup from 'mixitup';

$(window).on('load', function () {
    $('.js-filter1-wrap').each(function () {
        let wrap = this;
        let filter = $('[data-filter]', wrap);

        let mixer = mixitup(wrap, {
            controls: {
                scope: 'local'
            }
        });

        filter.on('click', function () {
            let data = $(this).attr('data-filter');
            filter.not(this).removeClass('is-active');
            $(this).addClass('is-active');
            $('[data-filter="' + data + '"]', wrap).addClass('is-active');
        });
    });
});

/*
$(window).on('load', () => {
  const filterWrap = $('.js-filter1-wrap');
  const filter = $('.js-filter1');

  if (filterWrap.length) {
    const elem = document.querySelectorAll('.js-filter1');

    elem.forEach((item) => {
      const parent = item.closest('.js-filter1-wrap');

      iso = new Isotope(item, {
        itemSelector: '.js-filter1-item',
      });

      console.log(iso);
      const btns = parent.querySelectorAll('.js-filter1-btn');

      const btnAll = $('[data-filter-all]');

      if (filter.length && btns.length) {
        btns.forEach((btn) => {
          btn.addEventListener('click', function () {
            const data = $(this).data('filter');

            iso = new Isotope(item, {
              itemSelector: '.js-filter1-item',
              filter: data,
            });

            btns.forEach((itemBtn) => {
              itemBtn.classList.remove('is-active');

              if (itemBtn.getAttribute('data-filter') === data) {
                itemBtn.classList.add('is-active');
              }
            });

            btn.classList.add('is-active');

            // Active All button when schoose
            if (btnAll.length) {
              if ($(this).data('filter-all') === '') {
                btns.forEach((itemBtn) => {
                  itemBtn.classList.remove('is-active');
                });
                parent
                  .querySelectorAll('[data-filter-all]')
                  .forEach((itemAll) => {
                    itemAll.classList.add('is-active');
                  });
              } else {
                parent
                  .querySelectorAll('[data-filter-all]')
                  .forEach((itemAll) => {
                    itemAll.classList.remove('is-active');
                  });
              }
            }
          });
        });
      }
    });
  }
});
*/
