import $ from 'jquery';

$('a[href*=\\#]:not([href=\\#])').click(function () {
  if (!$('body').hasClass('is-fixed')) {
    if (
      location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') &&
      location.hostname == this.hostname
    ) {
      var $target = $(this.hash);
      $target =
        ($target.length && $target) || $('[name=' + this.hash.slice(1) + ']');
      var offsetTop = $('.c-header').height();
      if ($target.length) {
        var targetOffset = $target.offset().top - offsetTop;
        $('html,body').animate({ scrollTop: targetOffset }, 1000);
        return false;
      }
    }
  }
});

$(function () {
  $(document).on('click', '#js-gotop a', function (e) {
    e.preventDefault();
    $('html, body').animate({ scrollTop: 0 }, 800);
  });
});

// click load scroll to id section
let target = window.location.hash;
target = target.replace('#', '');
window.location.hash = '';
$(window).on('load', function () {
  if (target) {
    $('html, body').animate(
      {
        scrollTop: $('#' + target).offset().top - $('.c-header').outerHeight(),
      },
      1000,
      'swing',
      function () { },
    );
  }
});

$('.c-menuheader .c-submenu1 a').on('click', function (event) {
  if ($(window).outerWidth() < 768) {
    if ($('body').hasClass('is-fixed')) {
      let target = $(this).attr('href').split('#')[1];
      if (target && $('#' + target).length) {
        event.preventDefault();
        $('.js-menu').trigger('click');
        setTimeout(function () {
          $('html, body').animate(
            { scrollTop: $('#' + target).offset().top - 100 },
            1000,
            'swing',
            function () { },
          );
        }, 400);
      }
    }
  }
});
